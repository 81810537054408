<template>
<div>
  <b-row class="match-height" v-if="resume">
    <b-col cols="12" v-show="isPusat">
      <b-card-actions 
        title="Unit"
        :actionCollapse="true">
        <b-col cols="8">
          <!-- <h3 class="font-weight-bolder">
            Unit
          </h3>
          <br> -->
          
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />

          <b-form-group
            label-cols-md="4"
            label="Periode"
          >
            <b-input-group>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="4"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-card-actions>
    </b-col>
    <b-col
      v-show="isPusat"
      xl="3"
      md="6"
      sm="12"
    >
      <statistic-card-horizontal
        icon="TruckIcon"
        color="primary"
        :statistic="resume.total_vehicle"
        statistic-title="Total Kendaraan"
      />
    </b-col>
    <b-col
      v-show="isPusat"
      xl="3"
      md="6"
      sm="12"
    >
      <statistic-card-horizontal
        icon="DownloadIcon"
        color="success"
        :statistic="resume.mutation_in"
        statistic-title="Mutasi Masuk"
      />
    </b-col>
    <b-col
      v-show="isPusat"
      xl="3"
      md="6"
      sm="12"
    >
      <statistic-card-horizontal
        icon="UploadIcon"
        color="danger"
        :statistic="resume.mutation_out"
        statistic-title="Mutasi Keluar"
      />
    </b-col>
    <b-col
      v-show="isPusat"
      xl="3"
      md="6"
      sm="12"
    >
      <statistic-card-horizontal
        icon="StopCircleIcon"
        color="warning"
        :statistic="(resume.total_spb>0?(resume.total_spb/resume.total_skko*100).toFixed(2):0)+'%'"
        :statistic-title="numFormat(resume.total_spb)+'/'+numFormat(resume.total_skko)"
      />
    </b-col>
  </b-row>
  <b-row>
    <b-col
      xl="12"
      md="12"
      sm="12"
    >
      <card-vehicle-region v-if="isRegion && vehicle && spb" :data="{vehicle:vehicle, spb:spb}" :type="'region'" />
    </b-col>
    <b-col
      xl="12"
      md="12"
      sm="12"
    >
      <card-vehicle-region v-if="isArea" :data="{vehicle:vehicleArea, spb:spbArea}" :type="'area'" />
    </b-col>
    <b-col
      xl="12"
      md="12"
      sm="12"
    >
      <card-vehicle-region v-if="isUlp" :data="{vehicle:vehicleUlp, spb:spbUlp}" :type="'ulp'" />
    </b-col>
    <b-col
      xl="12"
      md="12"
      sm="12"
    >
      <card-vehicle-region v-if="isKantorjaga" :data="{vehicle:vehicleKantorjaga, spb:spbKantorjaga}" :type="'kantorjaga'" />
    </b-col>
  </b-row>
</div>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import CardVehicleRegion from './include/CardVehicleRegion.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow, BCol, BCard, BCardActions,
    StatisticCardHorizontal,
    CardVehicleRegion,
    FormVSelect,
    BButton,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    flatPickr
  },
  data(){
    return {
      bbm:[],
      perawatan:[],
      kendaraan:[],
      klaim:[],
      resume:null,
      region:null,
      area:null,
      ulp:null,
      kantorjaga:null,
      dateStart:moment().startOf('year').format('YYYY-MM-DD'),
      dateEnd:moment().format('YYYY-MM-DD'),
      vehicle:null,
      spb:null,
      vehicleArea:null,
      spbArea:null,
      vehicleUlp:null,
      spbUlp:null,
      vehicleKantorjaga:null,
      spbKantorjaga:null,
      isPusat:false,
      isRegion:false,
      isArea:false,
      isUlp:false,
      isKantorjaga:false
    }
  },
  mounted(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      if(info.contact.kantorjaga){
        var params = {}
        params['filter[branch_id]']=info.contact.kantorjaga.id
        this.$http.get('dashboard/internal', {params:params}).then(res=>{
          this.vehicleKantorjaga = res.data.vehicle
          this.spbKantorjaga = res.data.spb
          this.isKantorjaga = true
        })
      }
      else if(info.contact.ulp){
        var params = {}
        params['filter[branch_id]']=info.contact.ulp.id
        this.$http.get('dashboard/internal', {params:params}).then(res=>{
          this.vehicleUlp = res.data.vehicle
          this.spbUlp = res.data.spb
          this.isUlp = true
        })
      }
      else if(info.contact.area){
        var params = {}
        params['filter[branch_id]']=info.contact.area.id
        this.$http.get('dashboard/internal', {params:params}).then(res=>{
          this.vehicleArea = res.data.vehicle
          this.spbArea = res.data.spb
          this.isArea = true
        })
      }
      else if(info.contact.region){
        this.region = info.contact.region
        this.isRegion = true
      }
      this.getJobTitle(info.contact.job_title_id)
      
    }
    this.getData()
  },
  methods:{
    getData(){
      this.vehicle = null
      var params = {}
      params['filter[branch_id]']=this.region?this.region.id:null
      params['between[received_date]']=this.dateStart + "," + this.dateEnd

      this.$http.get('dashboard/internal', {params:params}).then(res=>{
        this.resume = res.data.resume
        this.vehicle = res.data.vehicle
        this.spb = res.data.spb
        for(var index in res.data.refueling){
          this.bbm.push(res.data.refueling[index].amount)
        }
        for(var index in res.data.maintenance){
          this.perawatan.push(res.data.maintenance[index].amount)
        }
        for(var index in res.data.distribution){
          this.kendaraan.push(res.data.distribution[index].amount)
        }
        for(var index in res.data.claim){
          this.klaim.push(res.data.claim[index].amount)
        }
      })
    },
    getJobTitle(id){
      this.$http.get('job-title/'+id).then(res=>{
        this.isPusat = res.data.level=='pusat'?true:false
      })
    },
    show(){
      this.getData()
    },
    reset(){
      if(this.$store.state.profile.info){
        var info = this.$store.state.profile.info
        if(info.contact.region){
          this.region = info.contact.region
        }
      }
      this.dateStart = moment().startOf('month').format('YYYY-MM-DD'),
      this.dateEnd = moment().format('YYYY-MM-DD'),
      this.getData()
    },
    numFormat(number){
      var type = ''
      if(number > 1000000000000){
        number = (number/1000000000000).toFixed(3)
        type = ' T'
      }else if(number > 1000000000){
        number = (number/1000000000).toFixed(2)
        type = ' M'
      }else if(number > 1000000){
        number = (number/1000000).toFixed(2)
        type = ' Jt'
      }else if(number > 1000){
        number = (number/1000).toFixed(2)
        type = ' Rb'
      }

      if(type != '' && number.includes(".")){
        number = number.split('.')
        if(number[1] == 0){
          number = number[0]
        }
      }
      return number+type
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>