<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        Jumlah Kendaraan <span>{{type.toUpperCase()}}</span>
      </b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- View All -->
        <b-spinner v-if="!vehicle" small variant="primary" />
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col cols="6">
          <template>
            <vue-apex-charts 
              ref="chartDonutRegion" 
              type="donut" 
              height="300" 
              :options="pieChartOptions" 
              :series="dataDonutBranch.series"
            />
          </template>
        </b-col>
        <b-col cols="6">
          <template>
            <vue-apex-charts 
              ref="chartBarRegion" 
              type="bar" 
              height="300" 
              :options="lineChartOptions" 
              :series="dataBarBranch.series"
            />
          </template>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BSpinner
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { pieChartOptions } from '../../chartOptions'
import { lineChartOptions } from '../../chartOptions'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner,
    VueApexCharts
  },
  props:{
    data:{
      type:Object,
      default: function () {
        return {}
      },
    },
    type:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      vehicle: null,
      spb:null,
      colors:[
        'light-primary',
        'light-success',
        'light-info',
        'light-warning',
        'light-danger',
      ],
      dataDonutBranch:{
        label:[],
        series:[]
      },
      dataBarBranch:{
        series:[
          {name:'Rab', data:[]},
          {name:'Non Rab', data:[]}
        ]
      }
    }
  },
  computed: {
    pieChartOptions() {
      const options = JSON.parse(JSON.stringify(pieChartOptions))
      return options
    },
    lineChartOptions() {
      const options = JSON.parse(JSON.stringify(lineChartOptions))
      return options
    },
  },
  watch:{
    vehicle:function(data){
      this.getBranchVehicle(data)
    },
    spb:function(data){
      this.getBranchSpb(data)
    }
  },
  methods:{
    getBranchVehicle(data){
      this.dataDonutBranch = {label:[], series:[]}
      data.forEach(value => {
        this.dataDonutBranch.label.push(value.model)
        this.dataDonutBranch.series.push(value.count)
      });
      this.$refs.chartDonutRegion.updateOptions({ 
        labels: this.dataDonutBranch.label,
        dataLabels: {
          enabled: true,
          formatter: function(val, opt) {
            return opt.w.globals.series[opt.seriesIndex]
          }
        }
      })
    },
    getBranchSpb(data){
      this.dataBarBranch = {
        series:[
          {name:'Rab', data:[]},
          {name:'Non Rab', data:[]}
        ]
      }
      var label = []

      data.forEach(value => {
        this.dataBarBranch.series[0].data.push(value.spb_rab)
        this.dataBarBranch.series[1].data.push(value.spb_nonrab)
        label.push(moment('1-'+value.label, 'DD-MM-YYYY').format('MMM YYYY'))
      });
      this.$refs.chartBarRegion.updateOptions({ xaxis: {type:'string', categories: label} });
    }
  },
  created(){
    if(this.data.vehicle){
      this.vehicle = this.data.vehicle
    }
    if(this.data.spb){
      this.spb = this.data.spb
    }
  }
}
</script>
